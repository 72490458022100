<template>
  <form novalidate @submit.prevent="$emit('nextStep')">
    <div class="md:px-6">
      <h3 class="heading-5 mb-5">
        {{ $t('appoint-form-oneToOne-title', 'One-to-One Consultation') }}
      </h3>

      <p class="body-14-aa mb-6">
        {{
          $t(
            'appoint-form-oneToOne-text1',
            'Arrange an appointment with one of our experts for tailored advice on your jewellery purchase. Our consultants will show a selection of designs based on your preferences and price range to help find the ideal fit.',
          )
        }}
      </p>
      <button type="submit" class="cta-white" data-cy="book-now-button">
        {{ $t('book-now', 'Book Now') }}
      </button>
    </div>
  </form>
</template>

<style lang="scss"></style>

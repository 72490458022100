import type { FooterNavigationColumn } from './types'

const { eventBus, EVENTS } = useEventBus()

export const getCustomerCare = (i18nTranslation: any, localePath: any): FooterNavigationColumn => ({
  id: 'customer-care',
  title: i18nTranslation('footer-customer-care', 'customer care'),
  // link: translateURL("/NewApp/Default.aspx?app=engagement-rings"),
  items: [
    {
      title: i18nTranslation('footer-contact-us', 'Contact Us'),
      link: localePath('/contact'),
    },
    {
      dataCy: 'book-appointment-button',
      // link: translateURL("/NewApp/Default.aspx?app=contact"),
      // props:
      //   'onclick="event.preventDefault();" type="button" open-book-appointment="1" book-appointment-origin="footer"',
      title: i18nTranslation('footer-book-an-appointment', 'Book an Appointment'),
      onclick: () => eventBus.emit(EVENTS.OPEN_BOOK_APPOINTMENTS),
    },
    {
      title: i18nTranslation('footer-faqs', 'FAQs'),
      link: localePath('/faq.html'),
    },
    {
      title: i18nTranslation('footer-delivery-returns', 'Delivery & Returns'),
      link: localePath('/delivery-and-returns'),
    },
    {
      title: i18nTranslation('footer-finance-terms-conditions', 'Finance Terms & Conditions'),
      link: localePath('/finance-information'),
    },
    {
      title: i18nTranslation('footer-taxes-duties-calculator', 'Taxes and Duties Calculator'),
      link: localePath('/taxduty.html'),
    },
    {
      title: i18nTranslation('footer-finance-calculator', 'Finance Calculator'),
      link: localePath('/finance-calculator'),
      class: isUK() ? '' : 'hidden',
    },
    {
      title: i18nTranslation('footer-special-offers', 'Special Offers'),
      link: localePath('/offers.html'),
    },
  ],
})

export const getAboutUsSection = (i18nTranslation: any, localePath: any): FooterNavigationColumn => ({
  id: 'about-us',
  title: i18nTranslation('footer-about-us', 'About Us'),
  // link: translateURL("/NewApp/Default.aspx?app=engagement-rings"),
  items: [
    {
      title: capitalizeAll(i18nTranslation('footer-our-story', 'Our Story')),
      link: localePath('/our-story'),
    },
    {
      title: capitalizeAll(i18nTranslation('footer-our-showrooms', 'Our Showrooms')),
      link: localePath('/showrooms'),
    },
    {
      title: capitalizeAll(i18nTranslation('footer-our-promises-main-title', 'Our Promises')),
      link: localePath('/our-promises'),
    },
    {
      title: capitalizeAll(i18nTranslation('footer-responsible-sourcing', 'Responsible Sourcing')),
      link: localePath('/our-ethics'),
    },
    {
      title: capitalizeAll(i18nTranslation('footer-press', 'Press')),
      link: localePath('/77diamonds-press.html'),
    },
    {
      title: capitalizeAll(i18nTranslation('footer-awards', 'Awards')),
      link: localePath('/awards'),
    },
    {
      title: capitalizeAll(i18nTranslation('footer-testimonials', 'Testimonials')),
      link: localePath('/testimonials.html'),
    },
    {
      title: capitalizeAll(i18nTranslation('footer-careers', 'Careers')),
      link: localePath('/careers.html'),
    },
  ],
})

export const getLegalAndPrivacySection = (i18nTranslation: any, localePath: any): FooterNavigationColumn => ({
  id: 'legal-and-privacy',
  title: i18nTranslation('footer-legal-and-privacy', 'legal & privacy'),
  // link: translateURL("/NewApp/Default.aspx?app=engagement-rings"),
  items: [
    {
      title: i18nTranslation('footer-privacy-policy', 'Privacy Policy'),
      link: localePath('/privacy-77diamonds.html'),
    },
    {
      title: i18nTranslation('footer-cookie-policy', 'Cookie Policy'),
      link: localePath('/privacy-77diamonds.html') + '#information-about-our-user-of-cookies',
    },
    {
      title: i18nTranslation('footer-terms-conditions', 'Terms & Conditions'),
      link: localePath('/terms-conditions'),
    },
    ...(isGmbhGroup()
      ? [
          {
            title: i18nTranslation('footer-impressum', 'Impressum'),
            link: localePath('/impressum.html'),
          },
        ]
      : []),
  ],
  extraContent: {
    // textBeforeLink: i18nTranslation('footer-footer-third-column-specific', ''),
    textBeforeLink: '',
    title: i18nTranslation('footer-view-assay-assured', 'View Assay Assured Certification'),
    ariaLabel: 'view Assay Assured certification',
    wrapperClass: 'mt-3 hidden lg:block',
    linkClass: 'icon77 icon77-logo-assay',
    link: 'https://77diamonds.com/assets/Files/Hallmark/Hallmark-certificate.pdf',
  },
})

import type { SaleEvent } from '~/types/saleEvent'

const fetchEvents = () => $fetch('/api/getEvents') as Promise<SaleEvent[]>

export function useEvents() {
  const { data: events } = useAsyncData('events', fetchEvents)

  function getCurrentEvent(): SaleEvent | undefined {
    return events.value?.find((event) => Boolean(event.IsActive))
  }

  return {
    events,
    getCurrentEvent,
    currentEvent: getCurrentEvent(),
  }
}

<template>
  <div id="divShowHideLanguage" ref="dropdownContainerRef" class="showHideLanguageElement relative dropdown-wrapper">
    <div class="_sr-only">
      <a
        v-for="{ Language } in languages.LanguageSelections"
        :key="Language.Id"
        :title="Language.MenuName"
        :to="getURLByLanguage(Language.UrlCode)"
      >
        {{ Language.Name }}
      </a>
    </div>

    <button
      type="button"
      class="btn lg:my-n3 lg:ml-n3"
      data-cy="language-button"
      @click="displayDropdown = !displayDropdown"
    >
      <span v-if="flagUrl" class="flag mr-1" :style="{ backgroundImage: `url('${flagUrl}')` }"></span>
      <span id="ctl00_Header2019_CurrencySelection_lblcode" class="lblcode" data-cy="code">
        {{ getLanguageCurrencyCode }}
      </span>
    </button>

    <CurrencySelectionDropdown v-if="displayDropdown" />
  </div>
</template>

<script setup lang="ts">
import { useRoutesHelper } from '~/composables/useRoutesHelper'
import CurrencySelectionDropdown from './CurrencySelectionDropdown.vue'

import { useNewSettingsStore } from '~/store/newSettingsStore'

const props = defineProps({
  displayFlag: {
    type: Boolean,
    default: false,
  },
})

const { currentCountry, getLanguageCurrencyCode, languages } = storeToRefs(useNewSettingsStore())
const displayDropdown = ref(false)

const { getRouteByLanguage } = useRoutesHelper()
const { eventBus, EVENTS } = useEventBus()

const dropdownContainerRef = ref<HTMLElement>()

const flagUrl = computed(() => {
  return props.displayFlag && currentCountry.value ? `/images/NewImages/Flags/Big/${currentCountry.value.Name}.png` : ''
})

const getURLByLanguage = (UrlCode) => {
  return getRouteByLanguage(UrlCode)
}

onClickOutside(dropdownContainerRef, () => {
  displayDropdown.value = false
})

eventBus.on(EVENTS.OPEN_HEADER_CURRENCY_SELECTION, () => {
  displayDropdown.value = true
})
</script>

<style scoped lang="postcss">
#divShowHideLanguage {
  .flag {
    width: 20px;
    height: 20px;
    display: inline-block;
    border-radius: 50%;
    overflow: hidden;
    vertical-align: middle;
    background-position: center;
    background-size: 30px;
  }

  .lblcode {
    font-family: 'Plain Light', sans-serif;
    font-size: 12px;
    letter-spacing: 1px;
  }

  > .btn:after {
    content: '';
    display: inline-block;
    transform: rotate(45deg);
    border: solid;
    border-width: 0px 1px 1px 0px;
    height: 8px;
    width: 8px;
    margin-left: 8px;
    margin-bottom: 3px;
  }

  @media (max-width: 1023px) {
    padding: 16px 0;
    display: inline-block;
    border: 1px solid transparent;

    > .btn {
      margin-bottom: -1px;
      z-index: 2;
      position: relative;
      background: #fff;
      border-radius: 0;
    }
  }

  @media (min-width: 1024px) {
    margin: 0;
    padding-right: 12px;
  }

  ._sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
  }
}
</style>

<template>
  <form
    data-di-form-track
    data-di-form-id=""
    novalidate
    class="online-appointments--step5"
    data-cy="last-step"
    @submit.prevent="handleSubmit"
  >
    <button type="button" class="mb-4" @click="$emit('prevStep')">
      <i class="icon77 icon77-chevron -left"></i>
      <span class="heading-5">
        {{ $t('book-an-appointment', 'Book an Appointment') }}
      </span>
    </button>

    <ui-old-accordion v-model="current" :multiple="false" :show-arrow="true" title-class="body-12-aa-cap">
      <ui-old-accordion-item
        v-if="hasStones"
        value="1"
        opened
        :toggleable="firstStepHasValue"
        :onClose="handleOnBeforeCloseFirstStep"
        :class="{ '-error': !firstStepHasValue }"
      >
        <template #label="{ visible }">
          <i v-if="hasBeenSetOnce && firstStepHasValue" class="icon77shop ic_tick_green float-left mr-3" />

          <template v-if="!visible && firstStepHasValue">
            <template v-if="appointmentData.notsure">
              {{ $t('notsure', "I'm not sure") }}
            </template>
            <template v-else>
              {{
                [appointmentData.stoneType.value, ...appointmentData.shapes.map((st) => st.value)]
                  .map((v) => $t(...v))
                  .join(', ')
              }}
            </template>
          </template>

          <template v-else>
            {{ $t('appoint-form-stoneShape-preference', 'Stone Preferences') }}
          </template>
        </template>

        <div :class="{ disabled: appointmentData.notsure }">
          <div class="flex gap-2 md:gap-5">
            <div
              v-for="(st, stoneTypeIndex) in stoneTypes"
              :key="`stone-types--${stoneTypeIndex}`"
              class="flex-auto md:flex-1"
            >
              <h3 class="body-12-aa-cap">{{ $t(...st.label) }}</h3>
              <label v-for="(a, stoneItemIndex) in st.items" :key="`stone-item--${stoneItemIndex}`" data-cy="option">
                <input v-model="appointmentData.stoneType" type="radio" :value="a" />
                <span class="body-14-aa">
                  {{ $t(...(a.label || a.value)) }}
                  <i :class="`icon77 icon77-${a.icon} float-right`"></i>
                </span>
              </label>
            </div>
          </div>

          <p v-if="showErrorMessage" class="errorMessage">
            <span>
              {{ $t('appoint-form-stone-pref-error-message', 'Please select one or more options') }}
            </span>
          </p>

          <hr />

          <div class="grid grid-cols-2 gap-x-5">
            <div v-for="(a, shapeIndex) in shapes" :key="`shapes--${shapeIndex}`" class="">
              <label data-cy="option">
                <input v-model="appointmentData.shapes" type="checkbox" :value="a" />
                <span class="body-14-aa">
                  <i :class="`icon77 icon77-shape_${a.value[0]} mt-[-3px]`"></i>
                  {{ $t(...a.value) }}
                </span>
              </label>
            </div>
          </div>
        </div>

        <hr />

        <label data-cy="option">
          <input v-model="appointmentData.notsure" type="checkbox" class="rounded" @change="handleChangeNotSure" />
          <span class="body-14-aa">{{ $t('notsure', "I'm not sure") }}</span>
        </label>
      </ui-old-accordion-item>

      <ui-old-accordion-item
        v-if="hasStones"
        value="2"
        :class="{ '-error': !appointmentData.needIt && firstStepHasValue }"
        data-cy="need-it"
      >
        <template #label="{ visible }">
          <i v-if="appointmentData.needIt" class="icon77shop ic_tick_green float-left mr-3"></i>

          <template v-if="!visible && appointmentData.needIt">
            {{ $t(...appointmentData.needIt.label) }}
          </template>

          <template v-else>
            {{ $t('appoint-form-needIt-step', 'Need it in the next') }}
          </template>
        </template>

        <label v-for="(ni, needItIndex) in needItList" :key="`need-it--${needItIndex}`">
          <input v-model="appointmentData.needIt" type="radio" :value="ni" class="rounded" @change="current = 3" />
          <span class="body-14-aa">{{ $t(...ni.label) }}</span>
        </label>
      </ui-old-accordion-item>

      <ui-old-accordion-item
        v-if="hasStones"
        data-cy="price"
        value="3"
        :class="{ '-error': !priceOK }"
        @activated="priceOK = true"
      >
        <template #label="{ visible }">
          <i v-if="priceOK" class="icon77shop ic_tick_green float-left mr-3"></i>

          <template v-if="!visible && priceOK">
            {{ currency(appointmentData.price[0]) }} - {{ currency(appointmentData.price[1]) }}
          </template>

          <template v-else>
            {{ $t('appoint-form-priceRange-step', 'Price range') }}
          </template>
        </template>

        <div class="flex py-2 gap-3">
          <div class="flex-1">
            <select
              :key="`min-price-${priceOptions.length}`"
              v-model="appointmentData.price[0]"
              class="_form-control"
              data-cy="select-min"
            >
              <option
                v-for="(p, minPriceIndex) in priceOptions"
                :key="`min-price--${minPriceIndex}`"
                :value="p"
                data-cy="item"
              >
                {{ currency(p) }}
              </option>
            </select>
          </div>

          <VueSlider
            v-model="appointmentData.price"
            adsorb
            :data="priceOptions"
            :height="2"
            :marks="false"
            tooltip="none"
            class="flex-1 self-center"
          />

          <div class="flex-1">
            <select
              :key="`max-price-${priceOptions.length}`"
              v-model="appointmentData.price[1]"
              class="_form-control"
              data-cy="select-max"
            >
              <option
                v-for="(p, maxPriceIndex) in priceOptions"
                :key="`max-price--${maxPriceIndex}`"
                :value="p"
                data-cy="item"
              >
                {{ currency(p) }}
              </option>
            </select>
          </div>
        </div>
      </ui-old-accordion-item>

      <article v-if="isOtherGems" class="m-3">
        <h3 class="body-14-aa mb-2">{{ $t('appoint-form-looking-for', "I'm Looking for") }}:</h3>

        <div class="grid grid-cols-3 gap-4">
          <div v-for="lookingForOption in LookingForOptions" :key="`looking-for-option--${lookingForOption.id}`">
            <label data-cy="option">
              <input
                v-model="appointmentData.lookingFor"
                type="checkbox"
                :value="$t(lookingForOption.id, lookingForOption.label)"
              />
              <span class="body-14-aa font-normal">
                {{ $t(lookingForOption.id, lookingForOption.label) }}
              </span>
            </label>
          </div>
        </div>
      </article>

      <ui-old-accordion-item
        value="4"
        data-cy="notes"
        :toggleable="!isOtherGems && hasStones"
        :showArrow="!isOtherGems && hasStones"
        :opened="isOtherGems || !hasStones"
      >
        <template #label="{ visible }">
          <i
            v-if="appointmentData.notes && !isOtherGems && hasStones"
            class="icon77shop ic_tick_green float-left mr-3"
          />

          <template v-if="!visible && appointmentData.notes">
            {{ appointmentData.notes }}
          </template>

          <template v-else>
            {{ $t('appoint-form-notes', 'Notes') }}
          </template>
        </template>

        <textarea
          v-model="appointmentData.notes"
          :placeholder="
            $t(
              'appoint-form-notes-placeholder-new',
              'Enter any additional stone preferences (carat size, cut, clarity, etc), favourite jewellery styles or any design ideas you may have.',
            )
          "
          class="body-14-aa w-full outline-none h-[120px]"
          data-cy="notes-field"
        >
        </textarea>
      </ui-old-accordion-item>
    </ui-old-accordion>

    <button type="submit" :disabled="disableSubmit" class="cta-white my-5 continue" data-cy="submit-button">
      {{ $t('appoint-form-last-step-submitBtn', 'Submit request') }}
    </button>
  </form>
</template>

<script setup>
import VueSlider from 'vue-3-slider-component'
import { ExchangeRate } from '~/api/app'
import { useNewSettingsStore } from '~/store/newSettingsStore'

import { useOnlineAppointments } from '~/store/bookAppointmentStore'

import LookingForOptions from './contants/LookingForOptions'

const { SelectedInterest } = useOnlineAppointments()
const { getCurrentCurrencySymbol } = useNewSettingsStore()

const emit = defineEmits(['nextStep', 'prevStep'])

const exchangeRate = await ExchangeRate()

const stoneTypes = [
  {
    label: ['diamonds', 'diamonds'],
    class: '_col-7 _col-md-6',
    items: [
      {
        id: 1,
        type: 'nat',
        value: ['natural-diams', 'Natural Diamond'],
        label: ['natural', 'Natural'],
        icon: 'diamond_round',
      },
      {
        id: 3,
        type: 'nat',
        value: ['lab-grown-diams', 'Lab-grown Diamond'],
        label: ['lab-grown', 'Lab-grown'],
        icon: 'diamond_round',
      },
      {
        id: 198,
        type: 'fancy',
        value: ['yellow-coloured', 'Yellow Diamond'],
        label: ['yellow-coloured', 'Yellow/Coloured'],
        icon: 'diamond_round_yellow',
      },
    ],
  },
  {
    label: ['gemstones', 'Gemstones'],
    items: [
      {
        id: 366,
        type: 'gem',
        value: ['ruby', 'Ruby'],
        // label: 'Ruby',
        icon: 'ruby_round',
      },
      {
        id: 371,
        type: 'gem',
        value: ['emerald', 'Emerald'],
        // label: 'Emerald',
        icon: 'emerald_round',
      },
      {
        id: 367,
        type: 'gem',
        value: ['sapphire', 'Sapphire'],
        // label: 'Sapphire',
        icon: 'sapphire_round',
      },
    ],
  },
]

const shapes = [
  {
    id: 1,
    value: ['round', 'round'],
  },
  {
    id: 2,
    value: ['princess', 'princess'],
  },
  {
    id: 3,
    value: ['emerald', 'emerald'],
  },
  {
    id: 4,
    value: ['asscher', 'asscher'],
  },
  {
    id: 5,
    value: ['pear', 'pear'],
  },
  {
    id: 6,
    value: ['radiant', 'radiant'],
  },
  {
    id: 7,
    value: ['oval', 'oval'],
  },
  {
    id: 8,
    value: ['cushion', 'cushion'],
  },
  {
    id: 9,
    value: ['heart', 'heart'],
  },
  {
    id: 10,
    value: ['marquise', 'marquise'],
  },
]

const needItList = [
  {
    id: 3,
    label: ['less-than-one-month', 'Less than 1 month'],
  },
  {
    id: 0,
    label: ['twomonths', '2 months'],
  },
  {
    id: 1,
    label: ['sixmonths', '6 months'],
  },
  {
    id: 2,
    label: ['twelvemonths', '12 months'],
  },
]

const priceOptions = [
  300, 500, 800, 1000, 1500, 2000, 2500, 3000, 4000, 5000, 6000, 7000, 8000, 10000, 12000, 14000, 16000, 18000, 20000,
  30000, 40000, 50000, 60000, 75000,
].map((p) => parseInt(p * exchangeRate))

const hasStones = computed(() => SelectedInterest == 24 || SelectedInterest == 27)

const current = ref(hasStones ? 1 : 4)
const priceOK = ref(false)
const hasBeenSetOnce = ref(false)
const showErrorMessage = ref(false)

const appointmentData = ref({
  stoneType: null,
  shapes: [],
  notsure: false,
  needIt: false,
  price: [priceOptions[0], priceOptions[priceOptions.length - 1]],
  notes: '',
  lookingFor: [],
})

// This is the correct way it should be working. But it's not like this in the live site, so...
// const minPriceOptions = computed(() => priceOptions.filter((p) => p < appointmentData.value.price[1]))
// const maxPriceOptions = computed(() => priceOptions.filter((p) => p > appointmentData.value.price[0]))
const isOtherGems = computed(() => SelectedInterest === 47)
const firstStepHasValue = computed(() => Boolean(appointmentData.value.notsure || appointmentData.value.stoneType))

const currency = (value) => {
  const { currency } = useShop()
  return currency(value, getCurrentCurrencySymbol, 0)
}

const handleSubmit = () => {
  const { lookingFor, ...data } = appointmentData.value
  if (lookingFor.length !== 0) {
    data.notes += `<br/><strong>Selected Jewls:</strong>${lookingFor.join('<br/>')}`
  }

  emit('nextStep', { appointmentData: data })
}

const handleOnBeforeCloseFirstStep = () => {
  if (!firstStepHasValue.value) {
    showErrorMessage.value = true
    current.value = 1

    return
  }

  hasBeenSetOnce.value = true
}

const handleChangeNotSure = computed(() => {
  const ad = appointmentData.value

  if (ad.notsure === true) current.value = 2
})

const disableSubmit = computed(() => {
  const ad = appointmentData.value

  if (isOtherGems.value) {
    return ad.lookingFor.length === 0
  }

  return hasStones.value && (!(ad.notsure || ad.stoneType) || !ad.needIt || !priceOK.value)  
})

watch(
  () => appointmentData.value.notsure,
  () => {
    appointmentData.value = {
      ...appointmentData.value,
      stoneType: null,
      shapes: [],
    }
  },
)

watch(
  () => [appointmentData.value.notsure, appointmentData.value.stoneType],
  () => {
    if (firstStepHasValue.value) showErrorMessage.value = false
  },
)
</script>

<style lang="scss">
.online-appointments--step5 {
  input[type='checkbox'],
  input[type='radio'] {
    display: none;

    + span:before {
      content: '';
      width: 15px;
      height: 15px;
      display: inline-block;
      border: 1px solid;
      margin-right: 10px;
      vertical-align: text-bottom;
    }

    &:checked + span:before {
      background-color: #000;
      background-image: url(/images/shop/icons/full_tick.svg);
      background-position: 50%;
      background-size: 19px;
    }

    &.rounded + span:before {
      border-radius: 100%;
    }

    @media screen and (max-width: 374.8px) {
      ~ * .icon77 {
        display: none;
      }
    }
  }

  .accordion-item {
    border: 1px solid #dddddd;
    margin-bottom: 10px;

    &.-opened {
      border-color: #000;
    }

    &.dirty.-error:not(.-opened) {
      border-color: red;

      .accordion-item-label {
        border-color: red;
      }
    }

    .disabled {
      opacity: 0.6;
      pointer-events: none;
    }

    .accordion-item-label {
      position: relative;
      margin-bottom: -1px;
      padding: 10px 50px 10px 20px;
      border-top: none;

      .accordion-item-arrow {
        position: absolute;
        margin: 15px 20px;
        right: 0;
        top: 0;
        // width: 10px;
        // height: 10px;
        // transform: rotate(135deg);
        // box-shadow: 1px -1px 0.5px 0.8px;
      }
    }

    // .chevron-up {
    //     transform: rotate(-45deg);
    //     margin: 12px 25px;
    // }

    .accordion-item-content {
      padding: 10px 20px;
    }
  }

  .accordion-item-content {
    label {
      display: block;
      margin-bottom: 5px;
      line-height: 25px;
    }

    hr {
      margin: 10px -20px;
    }
  }

  .vue-slider-dot-handle {
    border: 1px solid;
    box-shadow: none;
  }

  .vue-slider-process {
    background-color: #000;
  }

  .errorMessage {
    transform: translate(-10px, -10px);

    span {
      color: #f00;
      font-family: 'Plain Light';
      font-size: 12px;
    }
  }
}
</style>

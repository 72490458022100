<template>
  <div class="md:container">
    <div class="flex gap-3 items-center overflow-hidden">
      <p class="body-10-aa text-grey-900 w-full text-start">
        <template v-if="companyCode === '77D'">
          {{
            $t(
              'footer-77limited-copyright',
              { year: currentYear, companyNumber: company.companyNumber, companyPhone: company.phone },
              '©{year} 77 Diamonds Limited - Registered in England and Wales - Registered office: 2nd Floor, 3 Hanover Square, London, W1S 1HD. Company number: {companyNumber}. Phone Number: {companyPhone}',
            )
          }}
        </template>

        <template v-else>
          &copy;{{ currentYear }} {{ company.name }} -
          <span> {{ company.address }}. </span>
          <span v-if="company.companyNumber">
            Company Number: <span class="text-nowrap"> {{ company.companyNumber }}. </span>
          </span>
          <span v-if="company.phone">
            <template v-if="companyCode === '77D-GMBH'"> Telefon: </template>
            <template v-else> Phone Number: </template>
            <span class="text-nowrap"> {{ company.phone }} </span>
          </span>

          <span v-if="company.extra" class="ml-0.5"> {{ company.extra }}</span>
        </template>
      </p>

      <a
        href="https://77diamonds.com/assets/Files/Hallmark/Hallmark-certificate.pdf"
        :title="$t('footer-view-assay-assured', 'View Assay Assured Certification')"
        rel="noopener"
        target="_blank"
        class="lg:hidden"
      >
        <span class="icon77 icon77-logo-assay-sm sm:hidden"></span>
        <span class="icon77 icon77-logo-assay hidden sm:block"></span>
      </a>

      <template v-if="isTestEnvironment">
        <div
          class="trustedsite-trustmark _my-n3 _mr-n2 loaded"
          data-type="301"
          data-width="150"
          data-height="60"
          title="TrustedSite Certified"
          tabindex="0"
          style="
            width: 150px;
            height: 62px;
            display: inline-block;
            background-image: url('/images/trusted-site/301.svg');
            background-size: contain;
            background-position: center top;
            background-repeat: no-repeat;
            cursor: pointer;
          "
        ></div>
      </template>
      <template v-else>
        <div class="trustedsite-trustmark _my-n3 _mr-n2" data-type="301" data-width="150" data-height="60"></div>
      </template>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useNewSettingsStore } from '~/store/newSettingsStore'

import { COMPANIES_CODE, COMPANY_DATA, type CompanyCodeOptions, type CompanyData } from '~/constants/companies'

const newSettingsStore = useNewSettingsStore()

const currentYear = new Date().getFullYear()

const isTestEnvironment = process.env.NODE_ENV !== 'production'

const companyCode = computed<CompanyCodeOptions>(() => {
  const company = COMPANIES_CODE[newSettingsStore.countryCompany?.CompanyId]

  return company?.code || '77D'
})

const company = computed<CompanyData>(() => {
  return COMPANY_DATA[companyCode.value] || COMPANY_DATA.default
})
</script>

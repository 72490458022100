<template>
  <form novalidate class="step-3" data-cy="third-step" @submit.prevent="">
    <button type="button" class="mb-3" @click="$emit('prevStep')">
      <i class="icon77 icon77-chevron -left"></i>
      <span class="heading-5">
        {{ $t('book-an-appointment', 'Book an Appointment') }}
      </span>
    </button>

    <div
      class="contactForm scrollable-container lg:pr-7"
      data-di-form-track
      data-di-form-id="appointment-form-step-1"
      data-ga4-form="Appointment Form"
    >
      <div class="leftCol form pb-7 pr-md-3">
        <h3 class="body-14-aa my-5">
          {{ $t('date-and-time', 'DATE & TIME') }}
        </h3>
        <h4 class="body-14-aa mb-2">
          {{ $t('what-would-you-like', 'What would you like to see?') }}
        </h4>

        <div class="interest-list mb-6 flex flex-col gap-1">
          <label v-for="i in Interests" class="block interest body-14-aa" data-cy="option">
            <input v-model="SelectedInterest" type="radio" :value="i.id" class="my-2 mr-1" />
            {{ $t(...i.label) }}
          </label>
        </div>

        <div v-show="SelectedInterest" ref="calendar" class="relative">
          <template v-if="true">
            <VDatePicker
              ref="vcalendar"
              :key="locationCode"
              v-model="SelectedDate"
              expanded
              borderless
              first-day-of-week="1"
              class="step3calendar border mar"
              :disabled-dates="DisabledDates"
              :is-required="true"
              :locale="locationCode"
              @did-move="refresh"
            >
              <template #day-content="{ day }">
                <div
                  tabindex="0"
                  role="button"
                  v-bind="day.attributes"
                  class="vc-day-content vc-focus vc-attr vc-day-box-center-center"
                  :class="{
                    'vc-focusable': day.isFocusable,
                    'vc-disabled': day.isDisabled,
                  }"
                  :aria-label="day.ariaLabel"
                  :aria-disabled="day.isDisabled"
                  :data-cy="isDaySelected(day) ? 'day-active' : 'day'"
                  @click="() => handleDayClick(day)"
                >
                  {{ day.label }}
                </div>
              </template>
            </VDatePicker>
          </template>
          <div v-if="DisabledDatesStatus" class="loading">
            <i class="icon77 icon77-loading"></i>
          </div>
        </div>

        <div v-if="SelectedDate && showHours" ref="meetUs" class="meetUs">
          <p class="body-16-aa mt-6">
            {{ $t('when-to-meet', 'When would you like to meet us?') }}
          </p>

          <div class="meetHours meetHoursDisabled flex gap-2 my-3">
            <button
              type="button"
              class="cta normal-case text-base border-none hover:bg-[#ffc13b4d] active:bg-[#ffc13b]"
              data-cy="meet-hour"
              @click="setPreferedTime('AM')"
            >
              {{ $t('morning', 'Morning') }} - AM
            </button>
            <button
              type="button"
              class="cta normal-case text-base border-none hover:bg-[#ffc13b4d] active:bg-[#ffc13b]"
              data-cy="meet-hour"
              @click="setPreferedTime('PM')"
            >
              {{ $t('afternoon', 'Afternoon') }} - PM
            </button>
          </div>

          <p
            class="mt-3 body-14-aa tailoredText"
            v-html="
              $t(
                'tailored-consultation',
                'Your tailored consultation will last approximately one hour. Our jewellery specialist will <b>need to confirm</b> your requested date, so please ensure you have received your booking confirmation before visiting us.',
              )
            "
          ></p>
        </div>
      </div>
    </div>
  </form>
</template>

<script setup>
import { useOnlineAppointments } from '~/store/bookAppointmentStore'

const { SelectedInterest, DisabledDates, DisabledDatesStatus, SelectedDate } = storeToRefs(useOnlineAppointments())
const { Interests, getDisabledDates } = useOnlineAppointments()

const { locationCode } = useDateFormat()

const emit = defineEmits(['nextStep'])

const preventRefresh = ref(false)
// There's an issue where if there's no available dates on the current month
// The calendar won't jump to the first available date (because it's on another month)
// This firstLoad ref is only to keep track and move the calendar to the first
// available month, so the user can see the correct date pre-selected
const firstLoad = ref(true)
const calendar = ref()
const vcalendar = ref()
const meetUs = ref()
const showHours = ref(false)

const currentPage = computed(() => {
  return vcalendar.value?.calendarRef?.pages[0]
})

// const interest = ref(props.selectedInterest || null)
watch(SelectedInterest, () => refresh())
watch(DisabledDatesStatus, (value) => {
  if (!value) {
    if (
      Number.isFinite(SelectedDate.value) &&
      firstLoad.value &&
      new Date().getMonth() !== SelectedDate.value.getMonth()
    ) {
      firstLoad.value = false
      preventRefresh.value = true

      vcalendar.value?.move({
        day: SelectedDate.value.getDate(),
        month: SelectedDate.value.getMonth() + 1,
        year: SelectedDate.value.getFullYear(),
      })
    }
    calendar.value.scrollIntoView({ behavior: 'smooth' })
  }
})

const handleDayClick = async (day) => {
  showHours.value = true
  SelectedDate.value = day.date

  await nextTick()

  meetUs.value.scrollIntoView({ behavior: 'smooth' })
}

const isDaySelected = (day) => {
  if (!day.date || !SelectedDate.value) return false
  return day.date.toDateString() === SelectedDate.value.toDateString()
}

const refresh = () => {
  if (preventRefresh.value === false) {
    getDisabledDates(currentPage.value)
  }

  preventRefresh.value = false
}

const setPreferedTime = (preferedTime) => {
  // preferedTime = time;
  emit('nextStep', { preferedTime })
}

onMounted(async () => {
  const currentDate = new Date()

  vcalendar.value?.move({
    day: currentDate.getDate(),
    month: currentDate.getMonth() + 1,
    year: currentDate.getFullYear(),
  })

  if (SelectedInterest.value) refresh()
})
</script>

<style lang="scss">
.step3calendar {
  .vc-day {
    display: flex;
    justify-content: center;
    align-items: center;
    transform-origin: 50% 50%;
  }
}

.step-3 {
  .contactForm.-scrollable {
    @media screen and (min-width: 768px) {
      position: absolute;
      height: 90%;
      width: 100%;
      overflow: auto;
    }
  }

  .interest-list {
    .interest {
      cursor: pointer;
    }

    input {
      width: 15px;
      height: 15px;
      vertical-align: -2px;
      accent-color: #232323;
    }
  }

  .loading {
    position: absolute;
    width: 100%;
    background: #ffffff9e;
    height: 100%;
    top: 0;
    z-index: 1;

    .icon77 {
      position: absolute;
      top: 50%;
      left: 50%;
      margin: -20px;
      z-index: 1;
    }
  }

  .vc-container {
    .vc-header {
      margin: 20px 10px;
    }

    .vc-title-wrapper {
      font-family: 'Plain Light';
      font-size: 14px;
    }

    .vc-base-icon {
      stroke: #000;
      stroke-width: 1px;
    }
  }
}
</style>

<template>
  <div id="giveawayContent" class="text-center overflow-hidden">
    <div class="p-6 overflow-hidden bg-white border-solid border relative border-[#e4e4e4]">
      <button
        type="button"
        class="close-giveaway absolute right-0 top-0 px-4 h-[44px]"
        aria-label="Close giveaway popup"
        @click="$emit('close-giveaway')"
      >
        <i class="icon77 icon77-exit"></i>
      </button>

      <h2 class="h4 tracking-normal mb-2">{{ giveawayTitle }}</h2>
      <p class="body-14-aa mb-2">
        {{ giveawayText }}
      </p>

      <div v-if="isVideoEvent">
        <video id="giveawayVideo" :src="giveawayVideo" muted playsinline autoplay loop height="300"></video>
      </div>

      <div v-else>
        <NuxtImg :src="giveawayImage" width="400" height="300" loading="lazy" :alt="giveawayTitle" />
      </div>

      <UiButton77 block :to="localePath('/enter-our-prize-draw')" class="my-3">
        {{ $t('sign-up-now', 'Sign up now') }}
      </UiButton77>

      <p class="mb-0 body-12-aa text-muted terms">
        <span
          class="giveaway-consent"
          v-html="
            $t(
              'giveaway-win-diamond-earrings-terms',
              {
                endHour: giveawayEndHour,
                endDate: giveawayEndDate,
                link_terms: localePath('/terms-conditions'),
                link_privacy_policy: localePath('/privacy-77diamonds.html'),
              },
              `*By entering your details, you consent to receive emails from 77 Diamonds. You are free to unsubscribe at any time. For further information, please read our <a href='{link_privacy_policy}'>privacy policy</a> and <a href='{link_terms}'>terms and conditions</a>. No cash alternative. This prize draw closes at {endHour} (GMT) on {endDate}`,
            )
          "
        ></span>
      </p>
    </div>
  </div>
</template>

<script setup lang="ts">
defineEmits(['close-giveaway'])
const { t } = useI18n()
const localePath = useLocalePath()
// TODO: replace mocked data with data from API (clarify API endpoint for this data)
const giveawayTitle = ref(t('giveaway-july-august-24-title', 'Win Today! A Beautiful Handcrafted Pearl Necklace'))
const giveawayText = ref(
  t('giveaway-july-august-24-text', 'The ideal gift is closer than you think. Sign up today for a chance to win!'),
)
const giveawayEndHour = ref('23:59')
const giveawayEndDate = ref('29 August')
const giveawayVideo = ref('/path/to/video.mp4')
const giveawayImage = ref('/images/Campaign/2024/Giveaways/April/Giveaway-FAB_x1.gif')
const isVideoEvent = ref(false)
</script>

<style lang="scss"></style>

<template>
  <div
    class="header-bag"
    :class="{ loading: loading }"
    :data-items="shoppingBagItemsCount"
    data-cy="shopping-bag-modal"
  >
    <div class="header-bag-overlay toggle-bag" />
    <div ref="shoppingBag" class="header-bag-content flex flex-col p-3 md:p-6">
      <button
        type="button"
        class="absolute right-6 -m-1.5 p-3 toggle-bag"
        data-cy="close-button"
        @click="$emit('close')"
      >
        <i class="float-right icon77 icon77-exit" />
      </button>

      <h2 class="heading-5 mt-3 mb-0" data-cy="header-shopping-bag">
        {{ $t('shopping-bag', 'Shopping Bag') }}
        <span class="h4">({{ shoppingBagItemsCount }})</span>
      </h2>

      <hr class="my-4" />

      <div v-if="shoppingBagItemsCount === 0 && !loading">
        <p class="font-plain text-sm">
          {{ $t('empty-shopping-bag', "You don't have any Item in your Shopping Bag.") }}
        </p>
      </div>

      <template v-else>
        <i v-if="loading" class="icon77 icon77-loading" />
        <div v-else id="shoppingBagContent" class="overflow-y-auto overflow-x-hidden">
          <div v-for="(Item, itemIndex) in Items" :key="Item.ItemId" class="">
            <div class="flex gap-4">
              <div class="basis-3/12">
                <div class="header-bag-image aspect-square">
                  <img
                    v-if="!itemWithError[itemIndex]"
                    :src="getImageSourceForShoppingBag(Item)"
                    :alt="Item.ItemDescription"
                    @error="handleFiledToLoad(itemIndex)"
                  />

                  <NuxtImg v-else :alt="Item.ItemDescription" src="/images/NewImages/Icons/Shapes/Large/Oval.png" />
                </div>
              </div>
              <div class="basis-9/12">
                <div class="mb-1.5 flex justify-between">
                  <div>
                    <h4 class="body-12-aa mb-0 -bold">{{ Item.ItemDescription }}</h4>
                    <p v-if="Item.MetalDescription" class="body-12-aa mb-0 text-grey-900">
                      {{ Item.MetalDescription }}
                    </p>
                  </div>
                  <div v-if="Item.ItemPrice.SettingPrice.FinalPrice.WebPrice.WithVat">
                    <h4 class="body-12-aa mb-0 -bold whitespace-nowrap">
                      {{ Item.ItemPrice.SettingPrice.FinalPrice.WebPrice.WithVat }}
                    </h4>
                  </div>
                </div>

                <div v-for="(diamond, index) in Item.Diamonds" :key="diamond.code" class="mb-1.5 flex justify-between">
                  <div>
                    <h4 class="body-12-aa mb-0 -bold">
                      {{ diamond.Carat }} {{ $t('carat', 'carat') }} {{ diamond.Shape }}
                      {{ diamond.StoneTypeName }}
                    </h4>
                    <p class="body-12-aa mb-0 text-grey-900">
                      {{ diamond.Colour }} {{ $t('colour', 'colour') }} | {{ diamond.Clarity }}
                      {{ $t('clarity', 'clarity') }} | {{ diamond.Cut }}
                    </p>
                  </div>
                  <h4 class="body-12-aa mb-0 -bold whitespace-nowrap">
                    {{ Item.ItemPrice.DiamondsPrice[index].Price.WebPrice.WithVat }}
                  </h4>
                </div>

                <button type="button" class="btn-link-sm capitalize" @click="removeItem(Item)">
                  {{ $t('remove', 'Remove') }}
                </button>
              </div>
            </div>
            <hr />
          </div>

          <template v-if="OrderPrice">
            <h5 class="body-12-aa -bold mb-1.5 flex justify-between mt-3">
              {{ $t('subtotal', 'Subtotal') }}
              <span>{{ OrderPrice.TotalPrice.FinalPrice.WebPrice.WithoutVat }}</span>
            </h5>

            <h5 class="body-12-aa -bold mb-1.5 flex justify-between">
              {{ $t('vat', 'VAT') }}
              <span>{{ OrderPrice.TotalPrice.FinalPrice.WebPrice.Vat }}</span>
            </h5>

            <h5 class="body-12-aa -bold mb-1.5 flex justify-between capitalize">
              {{ $t('insured-delivery', 'Insured Delivery') }}
              <span>{{ $t('free', 'Free') }}</span>
            </h5>

            <h5 v-if="OrderPrice.HasDiscount" class="body-12-aa -bold mb-1.5 flex justify-between">
              {{ $t('discount', 'Discount') }}
              <span>- {{ OrderPrice.TotalPrice.DiscountedPrice.WebPrice.WithoutVat }}</span>
            </h5>

            <h5 class="h5 -bold flex justify-between">
              {{ $t('total', 'Total') }}
              <span>{{ OrderPrice.TotalPrice.FinalPrice.WebPrice.WithVat }}</span>
            </h5>
          </template>
        </div>
        <NuxtLinkLocale to="/checkout" class="cta-white my-2 no-underline" @click="emits('close')">
          {{ $t('checkout', 'checkout') }}
        </NuxtLinkLocale>
        <NuxtLinkLocale to="/shop-bag" class="btn-link-sm text-center mt-1" @click="emits('close')">
          {{ $t('view-bag', 'view bag') }}
        </NuxtLinkLocale>
      </template>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useShoppingBagStore } from '~/store/shoppingBagStore.js'
import { useSetupTranslations } from '~/composables/useSetupTranslations'
import { PUBLIC_PAGES_ROUTES, PUBLIC_PAGES_ROUTES_ID } from '~/constants/routes'

const emits = defineEmits(['close'])

const route = useRoute()

const { Items, OrderPrice } = storeToRefs(useShoppingBagStore())
const { getBag, removeItem: removeItemFromBag } = useShoppingBagStore()
const { setupTranslationsByPageId } = await useSetupTranslations()

const shoppingBag = ref<HTMLDivElement>()
const itemWithError = ref<Record<number, boolean>>({})

const { t } = useI18n()
const {
  data,
  error,
  pending: loading,
} = useLazyAsyncData('shoppingBagItems', async () => {
  return await getBag('')
})

const shoppingBagItemsCount = computed(() => {
  return Items.value?.length || 0
})

onClickOutside(shoppingBag, () => {
  emits('close')
})

const removeItem = async (Item) => {
  loading.value = true
  try {
    const itemName = Item.ItemDescription
    const itemPrice = Item.ItemPrice.TotalPrice.FinalPrice.WebPrice.WithVat
    const preMessage = t(
      'remove-item-warning',
      {
        itemName,
        itemPrice,
      },
      `Are you sure you want to remove ${itemName} (${itemPrice})?`,
    )
    const message = preMessage.replace('<strong>', '').replace('</strong>', '')

    // TODO: display confirmation modal instead of confirm
    if (confirm(message)) {
      await removeItemFromBag(Item.BagUniqueCode)

      refreshNuxtData('category')
    }
  } catch (error) {
    console.error('Error removing item:', error)
  } finally {
    loading.value = false
  }
}

const handleGetTranslations = async () => {
  const languageCode = getLanguageCodeFromRoute(route)
  const pageId = PUBLIC_PAGES_ROUTES_ID[PUBLIC_PAGES_ROUTES.SHOP_BAG]

  await setupTranslationsByPageId(pageId, languageCode)
}

const handleFiledToLoad = (itemIndex: number) => {
  itemWithError.value[itemIndex] = true
}

onMounted(async () => {
  handleGetTranslations()
})
</script>

<style scoped lang="scss">
.header-bag {
  position: fixed;
  height: 100%;
  z-index: 1050;
  transition: all 0.4s;

  @media screen and (max-width: 479.8px) {
    width: 100%;
    top: 0;
    right: 0;
  }

  @media screen and (min-width: 480px) {
    width: 400px;
    top: 0;
    right: 0;
  }

  // .price {
  //   @apply body-12-aa mb-0 whitespace-nowrap;
  // }

  //ToDo: fix overlay transition
  .header-bag-overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    background: rgba(0, 0, 0, 0.6);
    z-index: -1;
    cursor: pointer;
    transition: all 0.4s;
  }

  .header-bag-content {
    background: #fff;
    height: 100%;
  }

  .header-bag-image {
    background-color: #fafafa;
    padding: 16px 5px;

    img {
      mix-blend-mode: darken;
    }
  }

  .icon77-loading {
    margin: 150px 155px;
  }

  &.loading {
    &:before {
      content: '';
      position: absolute;
      z-index: 1;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.3);
      pointer-events: none;
    }

    &[data-items='1'] .icon77-loading {
      margin: 92px 155px;
    }
  }
}
</style>

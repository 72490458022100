import { SEO_PAGES_ROUTES } from '~/constants/routes'

const { translateShopAspRouteToFriendlyUrl: translateShopRoute } = useAspRoutes()

export const getEngagementRingsMenu = (i18nTranslation: any, localePath: any) => ({
  id: 'engagement-rings',
  title: i18nTranslation('nav-engagement-rings', 'Engagement Rings'),
  link: translateShopRoute('/ShopNew/Default.aspx?category=1'),
  subItems: [
    {
      title: i18nTranslation('nav-all-engagement-rings', 'All Engagement Rings'),
      link: translateShopRoute('/ShopNew/Default.aspx?category=1'),
      class: 'md:hidden',
    },
    {
      title: i18nTranslation('nav-create-your-own', 'Create your own'),
      class: 'lg:order-first',
      subItems: [
        {
          title: i18nTranslation('nav-start-with-diamond-setting', 'Start with a diamond setting'),
          icon: 'icon77 icon77-nav_c_ring',
          link: translateShopRoute('/shopnew/default.aspx?category=1'),
        },
        {
          title: i18nTranslation('nav-start-with-gemstone-setting', 'Start with a gemstone setting'),
          icon: 'icon77 icon77-Sapph_Ring',
          link: translateShopRoute('/shopnew/default.aspx?category=1&stone=2&diamondType=-1&gem=367'),
        },
        {
          title: i18nTranslation('nav-start-with-diamond', 'Start with a diamond'),
          icon: 'icon77 icon77-nav_c_diamond',
          link: translateShopRoute('/ShopNew/Default.aspx?category=1&step=diamond'),
        },
        {
          title: i18nTranslation('nav-start-with-coloured-diamond', 'Start with a coloured diamond'),
          icon: 'icon77 icon77-Coloured',
          link: translateShopRoute('/ShopNew/Default.aspx?category=1&item=-3&step=diamond'),
        },
        {
          title: i18nTranslation('nav-start-with-gemstone', 'Start with a gemstone'),
          icon: 'icon77 icon77-nav_sapphire',
          link: translateShopRoute('/ShopNew/Default.aspx?category=1&stone=2&step=diamond&gem=367&item=-4'),
        },
      ],
    },
    {
      title: i18nTranslation('nav-shop-by-shape', 'Shop by Shape'),
      subItems: [
        {
          title: i18nTranslation('nav-round', 'Round'),
          link: translateShopRoute('/ShopNew/Default.aspx?category=1&shape=1'),
          icon: 'icon77 icon77-shape_round',
        },
        {
          title: i18nTranslation('nav-princess', 'Princess'),
          link: translateShopRoute('/ShopNew/Default.aspx?category=1&shape=2'),
          icon: 'icon77 icon77-shape_princess',
        },
        {
          title: i18nTranslation('nav-cushion', 'Cushion'),
          link: translateShopRoute('/ShopNew/Default.aspx?category=1&shape=8'),
          icon: 'icon77 icon77-shape_cushion',
        },
        {
          title: i18nTranslation('nav-oval', 'Oval'),
          link: translateShopRoute('/ShopNew/Default.aspx?category=1&shape=7'),
          icon: 'icon77 icon77-shape_oval',
        },
        {
          title: i18nTranslation('nav-pear', 'Pear'),
          link: translateShopRoute('/ShopNew/Default.aspx?category=1&shape=5'),
          icon: 'icon77 icon77-shape_pear',
        },
        {
          title: i18nTranslation('nav-emerald', 'Emerald'),
          link: translateShopRoute('/ShopNew/Default.aspx?category=1&shape=3'),
          icon: 'icon77 icon77-shape_emerald',
        },
        {
          title: i18nTranslation('nav-heart', 'Heart'),
          link: translateShopRoute('/ShopNew/Default.aspx?category=1&shape=9'),
          icon: 'icon77 icon77-shape_heart',
        },
        {
          title: i18nTranslation('nav-radiant', 'Radiant'),
          link: translateShopRoute('/ShopNew/Default.aspx?category=1&shape=6'),
          icon: 'icon77 icon77-shape_radiant',
        },
        {
          title: i18nTranslation('nav-asscher', 'Asscher'),
          link: translateShopRoute('/ShopNew/Default.aspx?category=1&shape=4'),
          icon: 'icon77 icon77-shape_asscher',
        },
        {
          title: i18nTranslation('nav-marquise', 'Marquise'),
          link: translateShopRoute('/ShopNew/Default.aspx?category=1&shape=10'),
          icon: 'icon77 icon77-shape_marquise',
        },
      ],
    },
    {
      title: i18nTranslation('nav-shop-by-style', 'Shop By Style'),
      // data from Nav.ascx.cs
      subItems: [
        {
          title: i18nTranslation('nav-solitaire', 'Solitaire'),
          link: translateShopRoute('shop.aspx?CatId=1&SubCatId=1'),
          icon: 'icon77 icon77-nav_ER_solitaire',
        },
        {
          title: i18nTranslation('nav-diamond-band', 'Diamond Band'),
          link: translateShopRoute('shop.aspx?CatId=1&SubCatId=36'),
          icon: 'icon77 icon77-nav_ER_diamband',
        },
        {
          title: i18nTranslation('nav-halo', 'Halo'),
          link: translateShopRoute('shop.aspx?CatId=1&SubCatId=35'),
          icon: 'icon77 icon77-nav_ER_halo',
        },
        {
          title: i18nTranslation('nav-vintage', 'Vintage'),
          link: translateShopRoute('shop.aspx?CatId=1&SubCatId=3'),
          icon: 'icon77 icon77-nav_ER_vintage',
        },
        {
          title: i18nTranslation('nav-trilogy', 'Trilogy'),
          link: translateShopRoute('shop.aspx?CatId=1&SubCatId=4'),
          icon: 'icon77 icon77-nav_ER_trilogy',
        },
      ],
    },
    {
      title: i18nTranslation('nav-shop-by-metal', 'Shop By Metal'),
      class: 'lg:order-last',
      subItems: [
        {
          title: i18nTranslation('nav-white-gold-18k', 'White Gold')
            .replace('(18k)', '')
            .replace('（18K）', '')
            .replace('(18к)', ''),
          link: translateShopRoute('/ShopNew/Default.aspx?category=1&metal=2'),
          icon: 'metal-icon white-gold',
        },
        {
          title: i18nTranslation('nav-yellow-gold-18k', 'Yellow Gold')
            .replace('(18k)', '')
            .replace('（18K）', '')
            .replace('(18к)', ''),
          link: translateShopRoute('/ShopNew/Default.aspx?category=1&metal=1'),
          icon: 'metal-icon yellow-gold',
        },
        {
          title: i18nTranslation('nav-rose-gold-18k', 'Rose Gold')
            .replace('(18k)', '')
            .replace('（18K）', '')
            .replace('(18к)', ''),
          link: translateShopRoute('/ShopNew/Default.aspx?category=1&metal=3'),
          icon: 'metal-icon rose-gold',
        },
        {
          title: i18nTranslation('nav-platinum-950', 'Platinum').replace('(950)', '').replace('（950）', ''),
          link: translateShopRoute('/ShopNew/Default.aspx?category=1&metal=4'),
          icon: 'metal-icon platinum',
        },
      ],
    },
    {
      title: i18nTranslation('nav-new-arrivals', 'New Arrivals'),
      link: translateShopRoute('/shopnew/default.aspx?category=1&sortBy=new-arrivals'),
      class: 'pb-0 mb-0',
    },
    {
      title: i18nTranslation('nav-bespoke-design', 'Bespoke Design'),
      link: localePath('/bespoke-jewellery'),
      class: 'pb-0 mb-0',
    },
    {
      title: i18nTranslation('nav-men-engagement-rings', "Men's Engagement Rings"),
      link: translateShopRoute('/ShopNew/Default.aspx?category=2&subcategories=41'),
      class: 'pb-0 mb-0',
    },
    {
      title: i18nTranslation('nav-lab-grown-engagement-rings', 'Lab Grown Engagement Rings'),
      link: translateShopRoute('/ShopNew/Default.aspx?category=1&stone=3&diamondType=-1'),
      class: 'border-0 p-0',
    },
    {
      title: i18nTranslation('nav-need-it-sooner', 'Need It Sooner?'),
      link: translateShopRoute('/shopnew/default.aspx?category=12&filter=1'),
      class: 'border-0 p-0 lg:order-first',
      highlight: i18nTranslation('nav-chose-pre-set-text1', 'Shop our Ready To Wear items for a faster delivery.'),
    },
  ],
})

export const getWeddingRingsMenu = (i18nTranslation: any, localePath: any) => ({
  id: 'wedding-rings',
  title: i18nTranslation('nav-wedding-rings', 'Wedding Rings'),
  link: translateShopRoute('/ShopNew/Default.aspx?category=2'),
  subItems: [
    {
      title: i18nTranslation('nav-all-wedding-rings', 'All Wedding Rings'),
      link: translateShopRoute('/ShopNew/Default.aspx?category=2'),
      class: 'md:hidden',
    },
    {
      title: i18nTranslation('nav-shop-by-style', 'Shop by Style'),
      subItems: [
        {
          link: translateShopRoute('/shopnew/default.aspx?category=2&subcategory=5'),
          title: i18nTranslation('nav-classic', 'Classic'),
        },
        {
          link: translateShopRoute('/shopnew/default.aspx?category=2&subcategories=5,41'),
          title: i18nTranslation('nav-mens-wedding-rings', "Men's Wedding Rings"),
        },
        {
          link: translateShopRoute('/shopnew/default.aspx?category=2&subcategory=23'),
          title: i18nTranslation('nav-womens-wedding-rings', "Women's Wedding Rings"),
        },
      ],
    },
    {
      title: i18nTranslation('nav-shop-by-metal', 'Shop by Metal'),
      subItems: [
        {
          link: translateShopRoute('/ShopNew/Default.aspx?category=2&metal=2'),
          icon: 'metal-icon white-gold',
          title: i18nTranslation('nav-white-gold-18k', 'White Gold')
            .replace('(18k)', '')
            .replace('（18K）', '')
            .replace('(18к)', ''),
        },
        {
          link: translateShopRoute('/ShopNew/Default.aspx?category=2&metal=1'),
          icon: 'metal-icon yellow-gold',
          title: i18nTranslation('nav-yellow-gold-18k', 'Yellow Gold')
            .replace('(18k)', '')
            .replace('（18K）', '')
            .replace('(18к)', ''),
        },
        {
          link: translateShopRoute('/ShopNew/Default.aspx?category=2&metal=3'),
          icon: 'metal-icon rose-gold',
          title: i18nTranslation('nav-rose-gold-18k', 'Rose Gold')
            .replace('(18k)', '')
            .replace('（18K）', '')
            .replace('(18к)', ''),
        },
        {
          link: translateShopRoute('/ShopNew/Default.aspx?category=2&metal=4'),
          icon: 'metal-icon platinum',
          title: i18nTranslation('nav-platinum-950', 'Platinum').replace('(950)', '').replace('（950）', ''),
        },
        {
          link: translateShopRoute('/ShopNew/Default.aspx?category=2&metal=25'),
          icon: 'metal-icon palladium',
          title: i18nTranslation('nav-palladium-950', 'Palladium').replace('(950)', '').replace('（950）', ''),
        },
      ],
    },
    {
      title: i18nTranslation('nav-new-arrivals', 'New Arrivals'),
      link: translateShopRoute('/shopnew/default.aspx?category=2&sortBy=new-arrivals'),
      class: 'pb-0 mb-0',
    },
    {
      title: i18nTranslation('nav-bespoke-design', 'Bespoke Design'),
      link: localePath('/bespoke-jewellery'),
      class: 'border-0 p-0',
    },
  ],
})

export const getEternityRingsMenu = (i18nTranslation: any, localePath: any) => ({
  id: 'eternity-rings',
  title: i18nTranslation('nav-eternity-rings', 'Eternity Rings'),
  link: translateShopRoute('/ShopNew/Default.aspx?category=3'),
  subItems: [
    {
      title: i18nTranslation('nav-all-eternity-rings', 'All Eternity Rings'),
      link: translateShopRoute('/ShopNew/Default.aspx?category=3'),
      class: 'md:hidden',
    },
    {
      title: i18nTranslation('nav-shop-by-style', 'Shop by Style'),
      // data from legacy Nav.ascx.cs
      subItems: [
        {
          title: i18nTranslation('nav-claw-set', 'Claw Set'),
          link: translateShopRoute('shop.aspx?CatId=3&SubCatId=21'),
          icon: 'icon77 icon77-nav_ET_claw',
        },
        {
          title: i18nTranslation('nav-channel-set', 'Channel Set'),
          link: translateShopRoute('shop.aspx?CatId=3&SubCatId=19'),
          icon: 'icon77 icon77-nav_ET_channel',
        },
        {
          title: i18nTranslation('nav-designer-set', 'Designer Set'),
          link: translateShopRoute('shop.aspx?CatId=3&SubCatId=24'),
          icon: 'icon77 icon77-nav_ET_designer',
        },
      ],
    },
    {
      title: i18nTranslation('nav-shop-by-metal', 'Shop by Metal'),
      subItems: [
        {
          link: translateShopRoute('/ShopNew/Default.aspx?category=3&metal=2'),
          icon: 'metal-icon white-gold',
          title: i18nTranslation('nav-white-gold-18k', 'White Gold')
            .replace('(18k)', '')
            .replace('（18K）', '')
            .replace('(18к)', ''),
        },
        {
          link: translateShopRoute('/ShopNew/Default.aspx?category=3&metal=1'),
          icon: 'metal-icon yellow-gold',
          title: i18nTranslation('nav-yellow-gold-18k', 'Yellow Gold')
            .replace('(18k)', '')
            .replace('（18K）', '')
            .replace('(18к)', ''),
        },
        {
          link: translateShopRoute('/ShopNew/Default.aspx?category=3&metal=3'),
          icon: 'metal-icon rose-gold',
          title: i18nTranslation('nav-rose-gold-18k', 'Rose Gold')
            .replace('(18k)', '')
            .replace('（18K）', '')
            .replace('(18к)', ''),
        },
        {
          link: translateShopRoute('/ShopNew/Default.aspx?category=3&metal=4'),
          icon: 'metal-icon platinum',
          title: i18nTranslation('nav-platinum-950', 'Platinum').replace('(950)', '').replace('（950）', ''),
        },
      ],
    },
    {
      title: i18nTranslation('nav-bespoke-design', 'Bespoke Design'),
      link: localePath('/bespoke-jewellery'),
      class: 'pb-0 mb-0',
    },
    {
      title: i18nTranslation('nav-need-it-sooner', 'Need It Sooner?'),
      link: translateShopRoute('/shopnew/default.aspx?category=12&filter=3'),
      class: 'border-0 p-0',
      highlight: i18nTranslation('nav-chose-pre-set-text1', 'Shop our Ready To Wear items for a faster delivery.'),
    },
  ],
})

export const getJewelleryMenu = (i18nTranslation: any, localePath: any) => ({
  id: 'jewellery',
  title: i18nTranslation('nav-jewellery', 'Jewellery'),
  link: localePath('/diamond-jewellery'),
  subItems: [
    {
      title: i18nTranslation('nav-jewellery', 'Jewellery'),
      link: localePath('/diamond-jewellery'),
      class: 'md:hidden',
    },
    {
      title: i18nTranslation('nav-create-your-own', 'Create your own'),
      subItems: [
        {
          title: i18nTranslation('nav-create-your-own-earrings', 'Create your own earrings'),
          icon: 'icon77 icon77-nav_c_earring',
          link: translateShopRoute('/shopnew/default.aspx?category=5'),
        },
        {
          title: i18nTranslation('nav-create-your-own-necklace', 'Create your own necklace'),
          icon: 'icon77 icon77-nav_c_necklaces',
          link: translateShopRoute('/shopnew/Default.aspx?category=4&subcategories=8,9'),
        },
      ],
    },
    {
      title: i18nTranslation('nav-earrings', 'Earrings'),
      link: translateShopRoute('/shopnew/default.aspx?category=5'),
      subItems: [
        {
          title: i18nTranslation('nav-diamond-hoop-earrings', 'Diamond Hoop Earrings'),
          icon: 'icon77 icon77-nav_EAR_hoops',
          link: translateShopRoute('/shopnew/default.aspx?category=5&subcategory=22'),
        },
        {
          title: i18nTranslation('nav-diamond-stud-earrings', 'Diamond Stud Earrings'),
          icon: 'icon77 icon77-nav_EAR_studs',
          link: translateShopRoute('/shopnew/default.aspx?category=5&subcategory=10'),
        },
        {
          title: i18nTranslation('nav-diamond-drop-earrings', 'Diamond Drop Earrings'),
          icon: 'icon77 icon77-nav_EAR_drops',
          link: translateShopRoute('/shopnew/default.aspx?category=5&subcategory=11'),
        },
      ],
    },
    {
      title: i18nTranslation('nav-necklaces', 'Necklaces'),
      link: translateShopRoute('/ShopNew/Default.aspx?category=4'),
      class: 'lg:mb-16',
      subItems: [
        {
          title: i18nTranslation('nav-diamond-pendants', 'Diamond Pendants'),
          icon: 'icon77 icon77-nav_NECK_pendants',
          link: translateShopRoute('/shopnew/default.aspx?category=4&subcategory=9'),
        },
        {
          title: i18nTranslation('nav-diamond-necklaces', 'Diamond Necklaces'),
          icon: 'icon77 icon77-nav_NECK_necklaces',
          link: translateShopRoute('/shopnew/default.aspx?category=4'),
        },
      ],
    },
    {
      title: i18nTranslation('nav-cocktail-rings', 'Cocktail rings'),
      link: translateShopRoute('/shopnew/default.aspx?category=6&subcategory=12'),
      class: 'lg:mb-1',
    },
    {
      title: i18nTranslation('nav-bracelets', 'Bracelets'),
      link: translateShopRoute('/shopnew/default.aspx?category=6&subcategory=13'),
      class: 'lg:mb-1',
    },
    {
      title: i18nTranslation('nav-collections', 'Collections'),
      class: 'small-submenu-link lg:mb-10',
      subItems: [
        {
          title: i18nTranslation('nav-toi-et-moi', 'Toi Et Moi'),
          link: translateShopRoute('/shopnew/default.aspx?category=6&subcategory=65'),
          isNew: true,
        },
        {
          title: i18nTranslation('nav-starlight', 'Starlight'),
          link: translateShopRoute('/shopnew/default.aspx?category=6&subcategory=63'),
        },
        {
          title: i18nTranslation('nav-pearl', 'Pearl'),
          link: translateShopRoute('/shopnew/default.aspx?category=6&subcategory=54'),
        },
        {
          title: i18nTranslation('nav-kaleida', 'Kaleida'),
          link: translateShopRoute('/shopnew/default.aspx?category=6&subcategory=53'),
        },
        {
          title: i18nTranslation('nav-mayfair', 'Mayfair'),
          link: translateShopRoute('/shopnew/default.aspx?category=6&subcategory=52'),
        },
        {
          title: i18nTranslation('nav-gaia', 'Gaia'),
          link: translateShopRoute('/shopnew/default.aspx?category=6&subcategory=51'),
        },
        {
          title: i18nTranslation('nav-confetti', 'Confetti'),
          link: translateShopRoute('/shopnew/default.aspx?category=6&subcategory=32'),
        },
        {
          title: i18nTranslation('nav-olympia', 'Olympia'),
          link: translateShopRoute('/shopnew/default.aspx?category=6&subcategory=50'),
        },
        {
          title: i18nTranslation('nav-tea', 'Tea'),
          link: translateShopRoute('/shopnew/default.aspx?category=6&subcategory=49'),
        },
        {
          title: i18nTranslation('nav-primrose', 'Primrose'),
          link: translateShopRoute('/shopnew/default.aspx?category=6&subcategory=47'),
        },
      ],
    },
    {
      title: i18nTranslation('nav-bespoke-design', 'Bespoke design'),
      link: localePath('/bespoke-jewellery'),
      class: 'lg:mb-1',
    },
    {
      title: i18nTranslation('nav-need-it-soon', 'Need It Soon?'),
      subItems: [
        {
          title: i18nTranslation('nav-diamond-earrings', 'Diamond Earrings'),
          class: 'link-highlight',
          link: translateShopRoute('/shopnew/default.aspx?category=12&filter=5'),
        },
        {
          title: i18nTranslation('nav-diamond-necklaces', 'Diamond Necklaces'),
          class: 'link-highlight',
          link: translateShopRoute('/shopnew/default.aspx?category=12&filter=4'),
        },
        {
          title: i18nTranslation('nav-diamond-bracelets', 'Diamond Bracelets'),
          class: 'link-highlight',
          link: translateShopRoute('/shopnew/default.aspx?category=12&filter=6&style=13'),
        },
      ],
    },
  ],
})

export const getDiamondsMenu = (i18nTranslation: any, localePath: any) => ({
  id: 'diamonds',
  title: i18nTranslation('nav-diamonds', 'Diamonds'),
  link: localePath('/diamonds'),
  subItems: [
    {
      title: i18nTranslation('nav-loose-diamonds', 'Loose diamonds'),
      link: localePath('/diamonds'),
      class: '-double lg:order-1',
      subItems: [
        {
          title: i18nTranslation('nav-round', 'Round'),
          link: translateShopRoute('/ShopNew/Default.aspx?category=7&subcategory=14&shape=1'),
          icon: 'icon77 icon77-shape_round',
        },
        {
          title: i18nTranslation('nav-princess', 'Princess'),
          link: translateShopRoute('/ShopNew/Default.aspx?category=7&subcategory=14&shape=2'),
          icon: 'icon77 icon77-shape_princess',
        },
        {
          title: i18nTranslation('nav-cushion', 'Cushion'),
          link: translateShopRoute('/ShopNew/Default.aspx?category=7&subcategory=14&shape=8'),
          icon: 'icon77 icon77-shape_cushion',
        },
        {
          title: i18nTranslation('nav-oval', 'Oval'),
          link: translateShopRoute('/ShopNew/Default.aspx?category=7&subcategory=14&shape=7'),
          icon: 'icon77 icon77-shape_oval',
        },
        {
          title: i18nTranslation('nav-pear', 'Pear'),
          link: translateShopRoute('/ShopNew/Default.aspx?category=7&subcategory=14&shape=5'),
          icon: 'icon77 icon77-shape_pear',
        },
        {
          title: i18nTranslation('nav-emerald', 'Emerald'),
          link: translateShopRoute('/ShopNew/Default.aspx?category=7&subcategory=14&shape=3'),
          icon: 'icon77 icon77-shape_emerald',
        },
        {
          title: i18nTranslation('nav-heart', 'Heart'),
          link: translateShopRoute('/ShopNew/Default.aspx?category=7&subcategory=14&shape=9'),
          icon: 'icon77 icon77-shape_heart',
        },
        {
          title: i18nTranslation('nav-radiant', 'Radiant'),
          link: translateShopRoute('/ShopNew/Default.aspx?category=7&subcategory=14&shape=6'),
          icon: 'icon77 icon77-shape_radiant',
        },
        {
          title: i18nTranslation('nav-asscher', 'Asscher'),
          link: translateShopRoute('/ShopNew/Default.aspx?category=7&subcategory=14&shape=4'),
          icon: 'icon77 icon77-shape_asscher',
        },
        {
          title: i18nTranslation('nav-marquise', 'Marquise'),
          link: translateShopRoute('/ShopNew/Default.aspx?category=7&subcategory=14&shape=10'),
          icon: 'icon77 icon77-shape_marquise',
        },
        {
          title: i18nTranslation('nav-kites-shields', 'Kites/Shields'),
          link: translateShopRoute('/ShopNew/Default.aspx?category=7&subcategory=14&shape=31'),
          icon: 'icon77 icon77-shape_kite',
        },
        {
          title: i18nTranslation('nav-old-cuts', 'Old Cuts'),
          link: translateShopRoute('/ShopNew/Default.aspx?category=7&subcategory=14&shape=30'),
          icon: 'icon77 icon77-shape_oldcut',
        },
        {
          title: i18nTranslation('nav-triangulars', 'Triangulars'),
          link: translateShopRoute('/ShopNew/Default.aspx?category=7&subcategory=14&shape=32'),
          icon: 'icon77 icon77-dia_triangular',
        },
      ],
    },
    {
      title: i18nTranslation('nav-matching-pairs', 'Matching pairs'),
      link: translateShopRoute('/ShopNew/Default.aspx?category=7&subcategory=15&item=-2'),
      class: 'lg:mb-1',
    },
    {
      title: i18nTranslation('nav-coloured-diamonds', 'Coloured diamonds'),
      link: translateShopRoute('/ShopNew/Default.aspx?category=7&subcategory=28&item=-3'),
      class: 'lg:mb-1',
    },
    {
      title: i18nTranslation('nav-lab-grown-diamonds', 'Lab-grown diamonds'),
      link: translateShopRoute('/ShopNew/Default.aspx?category=7&stone=3'),
      // Class = "lg:mb-1"
    },
    {
      title: i18nTranslation('nav-77s-ideal-cut-collection', "77's Ideal Cut Collection"),
      link: localePath('/cupids-ideal-diamonds'),
      class: 'lg:mb-0',
      subItems: [
        {
          title: i18nTranslation('nav-cupids-ideal-diamonds', "Cupid's Ideal Diamonds"),
          link: translateShopRoute('/ShopNew/Default.aspx?category=7&subcategory=14&cut=510'),
          icon: 'icon77 icon77-cupids',
        },
        {
          title: i18nTranslation('nav-cupids-ideal-lab-grown', "Cupid's Ideal Lab Grown"),
          link: translateShopRoute('/ShopNew/Default.aspx?category=7&subcategory=14&cut=510&stone=3'),
          icon: 'icon77 icon77-cupidslg',
          isNew: true,
        },
      ],
    },
    {
      title: i18nTranslation('nav-create-your-own', 'Create your own'),
      class: 'lg:order-first',
      subItems: [
        {
          title: i18nTranslation('nav-diamond-ring', 'Diamond ring'),
          icon: 'icon77 icon77-nav_c_ring',
          link: translateShopRoute('/shopnew/default.aspx?category=1'),
        },
        {
          title: i18nTranslation('nav-diamond-earrings', 'Diamond earrings'),
          icon: 'icon77 icon77-nav_c_earring',
          link: translateShopRoute('/shopnew/default.aspx?category=5'),
        },
        {
          title: i18nTranslation('nav-diamond-necklace', 'Diamond necklace'),
          icon: 'icon77 icon77-nav_c_necklaces',
          link: translateShopRoute('/ShopNew/Default.aspx?category=4'),
        },
      ],
    },
    {
      title: i18nTranslation('nav-express-delivery', 'Express Delivery'),
      link: translateShopRoute('/shopnew/default.aspx?category=7&quickShipping=1'),
      class: '-small lg:order-last',
      highlight: i18nTranslation(
        'nav-faster-delivery-diamonds',
        'Choose our quick-shipping diamonds for a faster delivery.',
      ),
    },
  ],
})

export const getGemstonesMenu = (i18nTranslation: any, localePath: any) => ({
  id: 'gemstones',
  title: i18nTranslation('nav-gemstones', 'Gemstones'),
  link: localePath('/gemstones'),
  subItems: [
    {
      title: i18nTranslation('nav-shop-by-shape', 'Shop by shape'),
      class: '-double',
      subItems: [
        {
          title: i18nTranslation('nav-round', 'Round'),
          link: translateShopRoute('/shopnew/default.aspx?category=7&stone=2&shape=4&item=-4'),
          icon: 'shape-icon icon77 icon77-shape_round',
        },
        {
          title: i18nTranslation('nav-cushion', 'Cushion'),
          link: translateShopRoute('/shopnew/default.aspx?category=7&stone=2&shape=7&item=-4'),
          icon: 'shape-icon icon77 icon77-shape_cushion',
        },
        {
          title: i18nTranslation('nav-oval', 'Oval'),
          link: translateShopRoute('/shopnew/default.aspx?category=7&stone=2&shape=5&item=-4'),
          icon: 'shape-icon icon77 icon77-shape_oval',
        },
        {
          title: i18nTranslation('nav-pear', 'Pear'),
          link: translateShopRoute('/shopnew/default.aspx?category=7&stone=2&shape=6&item=-4'),
          icon: 'shape-icon icon77 icon77-shape_pear',
        },
        {
          title: i18nTranslation('nav-heart', 'Heart'),
          link: translateShopRoute('/shopnew/default.aspx?category=7&stone=2&shape=8&item=-4'),
          icon: 'shape-icon icon77 icon77-shape_heart',
        },
        {
          title: i18nTranslation('nav-rectangle', 'Rectangle'),
          link: translateShopRoute('/shopnew/default.aspx?category=7&stone=2&shape=1&item=-4'),
          icon: 'shape-icon icon77 icon77-shape_rectangle',
        },
        {
          title: i18nTranslation('nav-marquise', 'Marquise'),
          link: translateShopRoute('/shopnew/default.aspx?category=7&stone=2&shape=9&item=-4'),
          icon: 'shape-icon icon77 icon77-shape_marquise',
        },
        {
          title: i18nTranslation('nav-square', 'Square'),
          link: translateShopRoute('/shopnew/default.aspx?category=7&stone=2&shape=3&item=-4'),
          icon: 'shape-icon icon77 icon77-shape_square',
        },
        {
          title: i18nTranslation('nav-other', 'Other'),
          link: translateShopRoute('/shopnew/default.aspx?category=7&stone=2&shape=2&item=-4'),
          icon: 'shape-icon icon77 icon77-shape_other',
        },
      ],
    },
    {
      title: i18nTranslation('nav-create-your-own', 'Create your own'),
      class: 'lg:order-first',
      subItems: [
        {
          title: i18nTranslation('nav-sapphire-ring', 'Sapphire Ring'),
          link: translateShopRoute('/ShopNew/Default.aspx?category=1&stone=2&diamondType=-1&gem=367'),
          icon: 'icon77 icon77-Sapph_Ring',
        },
        {
          title: i18nTranslation('nav-ruby-ring', 'Ruby Ring'),
          link: translateShopRoute('/ShopNew/Default.aspx?category=1&stone=2&diamondType=-1&gem=366'),
          icon: 'icon77 icon77-Ruby_Ring',
        },
        {
          title: i18nTranslation('nav-emerald-ring', 'Emerald Ring'),
          link: translateShopRoute('/ShopNew/Default.aspx?category=1&stone=2&diamondType=-1&gem=371'),
          icon: 'icon77 icon77-Em_Ring',
        },
        {
          title: i18nTranslation('nav-gemstone-necklace', 'Gemstone necklace'),
          link: translateShopRoute('/shopnew/default.aspx?category=4&subcategory=9&stone=2&gem=367&item=-4'),
          icon: 'icon77 icon77-Sapph_Necklace',
        },
      ],
    },
    {
      title: i18nTranslation('nav-gemstones', 'Gemstones'),
      link: localePath('/gemstones'),
      subItems: [
        {
          title: i18nTranslation('nav-sapphire', 'Sapphire'),
          icon: 'icon77 icon77-nav_GEM_sapphire',
          link: translateShopRoute('/shop.aspx?CatId=7&SubCatId=48&gem=367&stone=2&item=-4'),
        },
        {
          title: i18nTranslation('nav-ruby', 'Ruby'),
          icon: 'icon77 icon77-nav_GEM_ruby',
          link: translateShopRoute('/shop.aspx?CatId=7&SubCatId=48&gem=366&stone=2&item=-4'),
        },
        {
          title: i18nTranslation('nav-emerald', 'Emerald'),
          icon: 'icon77 icon77-nav_GEM_emerald',
          link: translateShopRoute('/shop.aspx?CatId=7&SubCatId=48&gem=371&stone=2&item=-4'),
        },
      ],
    },
  ],
})

export const getBespokeMenu = (i18nTranslation: any, localePath: any) => ({
  id: 'bespoke',
  title: i18nTranslation('nav-bespoke', 'Bespoke'),
  link: localePath('/bespoke-jewellery'),
})

export const getEducationMenu = (i18nTranslation: any, localePath: any) => ({
  id: 'education',
  title: i18nTranslation('nav-education', 'Education'),
  link: localePath('/diamond-education.html'),
  // link: 'diamond-education',
  subItems: [
    {
      title: i18nTranslation('nav-diamonds', 'Diamonds'),
      subItems: [
        {
          title: i18nTranslation('nav-the-7cs-menu', 'The 7CS'),
          link: localePath('/diamond-education.html'),
          // link: 'diamond-education',
        },
        {
          title: i18nTranslation('nav-diamond-guide', 'Diamond Guide'),
          link: localePath('/engagement-rings/diamond-buying-guide'),
        },
        {
          title: i18nTranslation('nav-lab-diamond-guide', 'Lab-grown Diamond Guide'),
          link: localePath('/lab-grown-diamonds'),
        },
        {
          title: i18nTranslation('nav-cupids-ideal-cut', "Cupid's Ideal Cut"),
          link: localePath('/cupids-ideal-diamonds'),
        },
        {
          title: i18nTranslation('nav-lg-cupids-ideal-cut', "Lab-Grown Cupid's Ideal"),
          link: localePath(SEO_PAGES_ROUTES.LAB_GROWN_CUPID_DIAMOND),
        },
      ],
    },
    {
      title: i18nTranslation('nav-gemstones', 'Gemstones'),
      subItems: [
        {
          title: i18nTranslation('nav-coloured-gem-guide', 'Coloured gemstone guide'),
          link: localePath('/gemstones/guide'),
        },
        {
          title: i18nTranslation('nav-sapphire-guide', 'Sapphire Guide'),
          link: localePath('/gemstones/sapphire/guide'),
        },
        {
          title: i18nTranslation('nav-ruby-guide', 'Ruby Guide'),
          link: localePath('/gemstones/ruby/guide'),
        },
        {
          title: i18nTranslation('nav-emerald-guide', 'Emerald Guide'),
          link: localePath('/gemstones/emerald/guide'),
        },
        {
          title: i18nTranslation('nav-pearl-guide', 'Pearl Guide'),
          link: localePath('/pearls/guide'),
        },
      ],
    },
    {
      title: i18nTranslation('nav-jewellery', 'Jewellery'),
      class: 'mb-0',
      subItems: [
        {
          title: i18nTranslation('nav-ring-size-guide', 'Ring Size Guide'),
          link: localePath('/engagement-rings/ring-size-chart'),
        },
        {
          title: i18nTranslation('nav-engagement-ring-guide', 'Engagement Ring Guide'),
          link: localePath('/engagement-rings/engagement-buying-guide'),
        },
      ],
    },
    {
      title: i18nTranslation('nav-responsibility', 'Responsibility'),
      link: localePath('/our-ethics'),
    },
  ],
})

export const getContactUsMenu = (i18nTranslation: any, localePath: any) => ({
  id: 'contact-us',
  title: i18nTranslation('nav-contact-us', 'Contact Us'),
  class: 'lg:hidden',
  link: localePath('/contact'),
})
